import { default as React, FunctionComponent } from 'react'
import { iUser } from '../interfaces'
import * as clipboard from 'clipboard-polyfill'
import { ActionsRow, Button, Confirmation, SignaturePreviewRow } from '../styles'
import {
  linkStyle,
  logoStyle,
  nameStyle,
  previewAddressStyle,
  previewContactStyle,
  previewPhoneStyle,
  separatorStyle,
  contactInfoContainer
} from '../previewStyles'

export const SignaturePreview: FunctionComponent<{
  user: iUser
  onEdit: Function
}> = props => {
  const [copied, setCopiedStatus] = React.useState<boolean>(false)

  const handleCopyPreviewClick = () => {
    copyToClipboard()
    setCopiedStatus(true)
  }

  const handleEditSignatureClick = () => {
    setCopiedStatus(false)
    props.onEdit()
  }

  const copyToClipboard = () => {
    const preview = document.getElementById('preview')
    const data = new clipboard.DT()

    if (!preview) return

    const signatureContent = preview.innerHTML
    data.setData('text/plain', signatureContent)
    data.setData('text/html', signatureContent)
    clipboard.write(data)
  }

  return (
    <>
      <SignaturePreviewRow>
        <div id='preview' style={{ padding: '10px'}} onClick={copyToClipboard}>
          <p style={nameStyle}>
            {props.user.name} <br />
            {props.user.position}
          </p>
          <img
            style={logoStyle}
            width='91'
            height='26'
            src='https://cdn.shopify.com/s/files/1/0139/8942/files/signatureLogo.png?v=1592865120'
            title='FIGS Logo'
            alt='Figs Logo'
          />


          <div style={contactInfoContainer}>
            <div style={previewAddressStyle}>
              <span>2834 Colorado Ave. Suite 100</span>
              <br />
              Santa Monica, California 90404
            </div>

            <div style={previewPhoneStyle}>
              {props.user.phone && (
                <>
                  Office:{' '}
                  <a href={`tel:${props.user.phone}`} style={linkStyle}>
                    {props.user.phone}
                  </a>
                </>
              )}

              {props.user.phone && props.user.cell && <>{' // '}</>}

              {props.user.cell && (
                <>
                  Cell:{' '}
                  <a href={`tel:${props.user.cell}`} style={linkStyle}>
                    {props.user.cell}
                  </a>
                </>
              )}
            </div>

            <div style={previewContactStyle}>
              <a style={linkStyle} href='https://wearfigs.com' rel='noopener noreferrer'>
                www.wearFIGS.com
              </a>
              <span style={separatorStyle}>|</span>
              <a
                style={linkStyle}
                href='https://www.instagram.com/wearfigs/'
                rel='noopener noreferrer'
              >
                @wearfigs
              </a>
            </div>
          </div>



        </div>
      </SignaturePreviewRow>
      <ActionsRow>
        <Button onClick={handleEditSignatureClick}>Back</Button>
        <Button onClick={handleCopyPreviewClick}>Next: Copy Signature</Button>
      </ActionsRow>
      {copied && (
        <Confirmation>Your FIGS Email Signature has been copied to your clipboard.</Confirmation>
      )}
    </>
  )
}
