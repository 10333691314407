import { default as React, FunctionComponent } from 'react'
import { useFormik } from 'formik'
import { Box, Button, FieldGroup, Form, Input, Label, SignatureFormRow } from '../styles'
import { iUser } from '../interfaces'

export const SignatureForm: FunctionComponent<{ user: iUser; onFormSubmit: Function }> = props => {
  const formik = useFormik({
    initialValues: { ...props.user },
    onSubmit: values => {
      props.onFormSubmit(values)
    },
  })
  return (
    <SignatureFormRow>
      <Form onSubmit={formik.handleSubmit}>
        <FieldGroup>
          <Label htmlFor='name'>Your Name</Label>
          <Input
            id='name'
            name='name'
            type='name'
            onChange={formik.handleChange}
            value={formik.values.name}
          />
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='email'>Email Address</Label>
          <Input
            id='email'
            name='email'
            type='email'
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='phone'>Office Phone Number</Label>
          <Input
            id='phone'
            name='phone'
            type='phone'
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='cell'>Cell Number (not required)</Label>
          <Input
            id='cell'
            name='cell'
            type='cell'
            onChange={formik.handleChange}
            value={formik.values.cell}
          />
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='position'>Position at FIGS</Label>
          <Input
            id='position'
            name='position'
            type='position'
            onChange={formik.handleChange}
            value={formik.values.position}
          />
        </FieldGroup>
        <Box>
          <Button type='submit'>Next: Preview</Button>
        </Box>
      </Form>
    </SignatureFormRow>
  )
}
