import styled from 'styled-components'
import { motion } from 'framer-motion'

const fontSize = '13px'

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

export const MainContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

export const Card = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: solid 1px red;
  max-height: 567px;
  width: 400px;
  background-color: #fff;
  border-color: #ddd #ddd #d8d8d8;
  box-shadow: 0 2px 0 hsla(0, 0%, 68.6%, 0.12);
  padding: 20px;
`

export const Box = styled(motion.div).attrs({
  initial: 'hidden',
  animate: 'visible',
  variants: fadeInVariants,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Row = styled(motion.div).attrs({
  initial: 'hidden',
  animate: 'visible',
  variants: fadeInVariants,
})`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const SignatureFormRow = styled(Box)`
  align-self: center;
`

export const SignaturePreviewRow = styled(Box)`
  align-self: center;
  border: dashed 2px #CCC;
`

export const ContentRow = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
`

export const LogoRow = styled(Row)`
  border-color: #ddd #ddd #d8d8d8;
  box-shadow: 0 2px 0 hsla(0, 0%, 68.6%, 0.12);
  padding-bottom: 20px;
  align-self: center;
`

export const ActionsRow = styled(Row)`
  align-self: space-around;
  margin: 20px;
`

export const Logo = styled(motion.img)`
  width: 144px;
  height: 40px;
`

export const Button = styled(motion.button).attrs({
  initial: 'hidden',
  animate: 'visible',
  variants: fadeInVariants,
})`
  background: #000000;
  min-width: 100px;
  max-width: 400px;
  padding: 10px;
  color: #ffffff;
  font-weight: bold;
  font-size: 13px;
  margin: 7px;
  cursor: pointer; 
 
`

export const Link = styled(motion.a).attrs({
  initial: 'hidden',
  animate: 'visible',
  variants: fadeInVariants,
})`
  padding: 10px;
  color: #FFF;
  font-weight: bold;
  font-size: 11px;
  margin: 7px;
  cursor: pointer; 
`

export const FieldGroup = styled(motion.div).attrs({
  initial: 'hidden',
  animate: 'visible',
  variants: fadeInVariants,
})`
  border: solid 0px orange;
  margin-bottom: 10px;
`

export const Form = styled(motion.form).attrs({
  initial: 'hidden',
  animate: 'visible',
  variants: fadeInVariants,
})``

export const Label = styled(motion.label).attrs({
  initial: 'hidden',
  animate: 'visible',
  variants: fadeInVariants,
})`
  min-width: 150px;
  color: #393941;
  font-size: ${fontSize};
  display: inline-block;
`

export const Input = styled(motion.input).attrs({
  initial: 'hidden',
  animate: 'visible',
  variants: fadeInVariants,
})`
  color: #393941;
  font-size: ${fontSize};
  border: solid 1px #ccc;
  padding: 5px;
  width: 100%;
`

export const Confirmation = styled(Box)`
  padding: 20px;
  background-color: #65666A;
  color: #FFF;
  font-weight: bold;
`
