import { default as React } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { iUser } from './interfaces'
import { ActionsRow, Card, ContentRow, Logo, LogoRow, MainContainer } from './styles'
import { AuthButton } from './components/AuthButton'
import { SignatureForm } from './components/SignatureForm'
import { SignaturePreview } from './components/SignaturePreview'

const App = () => {
  const { authState, authService } = useOktaAuth()
  const [user, setUser] = React.useState<iUser>()
  const [previewData, setPreviewData] = React.useState<iUser | undefined>(user)

  const login = async () => {
    await authService.login('/')
  }

  const refreshPreview = (userData: iUser) => {
    setPreviewData(userData)
    setUser(userData)
  }

  const clearPreview = () => {
    setPreviewData(undefined)
  }

  React.useEffect(() => {
    console.log(authState)

    if (authState.isAuthenticated) {
      authService.getUser().then((data: any) => {
        const { name, email } = data
        setUser({ name, email, position: 'Ambassador of Awesome', phone: '310-504-3709', cell: '' })
      })
    }
  }, [authState, authService])

  if (!authState.isAuthenticated) {
    return (
      <MainContainer>
        <AuthButton authAction={login}>Login</AuthButton>
      </MainContainer>
    )
  }

  if (authState.isPending) {
    return <p>Loading...</p>
  }

  return (
    authState.isAuthenticated && (
      <MainContainer>
        <Card>
          <LogoRow>
            <Logo src={'logo.png'} />
          </LogoRow>

          <ContentRow>
            {user && !previewData && (
              <SignatureForm user={{ ...user }} onFormSubmit={refreshPreview} />
            )}

            {previewData && <SignaturePreview user={previewData} onEdit={clearPreview} />}
          </ContentRow>

          <ActionsRow>

          </ActionsRow>
        </Card>
      </MainContainer>
    )
  )
}

export default App
