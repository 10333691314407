import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react'
import {Test} from "./components/Test";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Security
        issuer={`${process.env.REACT_APP_OKTA_ORG_URL}`}
        clientId={process.env.REACT_APP_OKTA_CLIENT_ID}
        redirectUri={`${window.location.origin}/implicit/callback`}
        scopes={['openid', 'profile', 'email']}
        pkce={true}
      >
        <Route exact path='/implicit/callback' component={LoginCallback} />
        <SecureRoute exact path='/' component={App} />
        <Route exact path='/test' component={Test} />
      </Security>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
