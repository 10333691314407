import { CSSProperties } from 'react'

const fontFamily = 'Helvetica, Arial, sans-serif'

export const separatorStyle: CSSProperties = {
  color: '#888888',
  fontSize: '10px',
  margin: '0 5px',
}

export const nameStyle: CSSProperties = {
  textTransform: 'uppercase',
  marginBottom: '5px',
  color: '#666666',
  fontFamily: fontFamily,
  fontSize: '12px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '18px',
  letterSpacing: 'normal',
}

export const logoStyle: CSSProperties = {
  marginTop: '13px',
  marginBottom: '2px',
}

export const contactInfoContainer: CSSProperties = {
  fontFamily: fontFamily,
  fontSize: '10px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '16px',
  letterSpacing: 'normal',
  color: '#888888',
}

export const previewAddressStyle: CSSProperties = {
  textDecoration: 'none',
  textTransform: 'initial',
}

export const previewPhoneStyle: CSSProperties = {
  textDecoration: 'none',
  textTransform: 'initial',
}

export const previewContactStyle: CSSProperties = {
  textDecoration: 'none',
  textTransform: 'initial',
}

export const linkStyle: CSSProperties = {
  fontSize: '10px',
  color: '#1155cc',
  fontFamily: fontFamily,
}
