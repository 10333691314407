import { default as React, FunctionComponent } from "react";
import { Link } from "../styles";

export const AuthButton: FunctionComponent<{
  authAction: () => {}
}> = props => {
  return (
    <Link onClick={props.authAction}>
      {props.children}
    </Link>
  )
}